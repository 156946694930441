.bordaTexto {
  -webkit-text-stroke-width: 4px; 
  -webkit-text-stroke-color: #000;
}

.bordaTextoSub {
  -webkit-text-stroke-width: 1px; 
  -webkit-text-stroke-color: #000;
}


.experiencia {
  margin-top: 40px;
  list-style: none;
}

.experiencia > li {
  padding: 10px;
  border-left: 2px solid #fff;
}

.experiencia > li:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.activeList {
  background-color: rgba(0, 0, 0, 0.2);
}

.inactiveList {
  background: none;
}

.atribuitions {
  margin-top: 40px;
  list-style: circle;
}

.atribuitions > li {
  padding: 0.2rem;
}
