
ul {
  list-style-type: none;
}

ul li {
  display: inline;
  padding: 2rem;
}

/* CELULAR */
@media screen and (max-device-width: 480px) {
  ul li {
    padding: 0.2rem;
  }
}


/* MID */
@media screen and (min-width: 481px) and (max-width: 900px) {
  ul li {
    padding: 1rem;
  }
}
