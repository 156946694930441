.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 20px
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,1);
}


.cardWhite {
  box-shadow: 0 4px 8px 0 rgba(143, 138, 138, 0.486);
  transition: 0.3s;
  border-radius: 20px
}

.cardWhite:hover {
  box-shadow: 0 8px 16px 0 rgba(143, 138, 138, 0.486);
}
